<template>
  <div
    :class="[
      'power-by',
      // campaignData.theme_type === 'verticle' ? 'power-by-vertical' : '',
    ]"
  >
    <a @click="redirectTo">
      {{ this.campaignData.powered_by_text }}
    </a>
  </div>
</template>

<script>
export default {
  name: "PowerBy",
  props: {
    campaignData: Object,
  },
  methods: {
    redirectTo() {
      if (this.campaignData.powered_by_link) {
        window.open(this.campaignData.powered_by_link, "_blank");
      }
    },
  },
};
</script>